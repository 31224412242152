#gjs {
    border: none;
  }
  /* Theming */
  
  /* Primary color for the background */
  .gjs-one-bg {
    background-color: #ffffff;
  }
  
  /* Secondary color for the text color */
  .gjs-two-color {
    color: rgb(0, 0, 0);
  }
  
  /* Tertiary color for the background */
  .gjs-three-bg {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
  }
  
  /* Quaternary color for the text color */
  .gjs-four-color,
  .gjs-four-color-h:hover {
    color: #248afd;
  }

  /* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-sm-sector-title{
  background-color: inherit;
  border-bottom: 0px;
  padding: 9px 10px 9px 2px;
}

.gjs-sm-sector.gjs-sm-open{
  border-bottom: 0px;
}

.sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 18%;
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;

  background-color: rgba(255, 255, 255, 0.95);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.sidenav .logo {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
}

.sidenav .pages {
  height: 100px;
  overflow: scroll;
  overflow-x: hidden;
}

.main-content {
  position: relative;
  width: 82%;
  height: 95%;
  left: 18%;
  margin-left: 5px;
}

.main-content .navbar {
  padding: 0;
  margin-bottom: 5px;
}

.main-content .navbar .container-fluid {
  padding: 0;
}

.gjs-pn-panel {
  position: relative;
}

.gjs-cv-canvas {
  width: 100%;
  height: 100%;
  top: 0;
}

.tab-content {
  display: contents;
}

#block {
  height: 100%;
}

#block #blocks {
  height: 100%;
}

#block #blocks .gjs-blocks-c {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#block #blocks .gjs-block {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* we do this to not to show eye icon in preview mode */
.gjs-off-prv{
  display: none !important;
}

#sidebar-readonlymode{
  width: 100%;
  min-height: calc(100vh - 49px);
  background: #ffffff;
}

.gjs-pn-btn{
  color:#787878
}

.gjs-pn-btn:hover{
  color: #393737
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  border: 0px;
}

/* style for fields */

.gjs-field{
  background-color:#ffffff;
  border: 1px solid gray;
  color: black;
  
}

.gjs-field-checkbox input:checked + 
.gjs-chk-icon {
  border-color: rgba(0, 0, 0, 0.5);
}

.gjs-color-main, .gjs-sm-stack #gjs-sm-add, .gjs-off-prv {
  color: #000;
  fill: #000;
}

.gjs-sm-field.gjs-sm-composite, .gjs-sm-composite.gjs-clm-select, .gjs-sm-composite.gjs-clm-field {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.gjs-sm-field input, .gjs-clm-select input, .gjs-clm-field input, .gjs-sm-field select, .gjs-clm-select select, .gjs-clm-field select {
  color: rgba(0, 0, 0, 0.7);
}

.gjs-field-arrow-u {
  border-bottom: 4px solid rgba(0, 0, 0, 0.7);
}

.gjs-field-arrow-d {
  border-top: 4px solid rgba(0, 0, 0, 0.7);
}

.gjs-field .gjs-d-s-arrow {
  border-top: 4px solid rgba(0, 0, 0, 0.7);
}

.gjs-sm-property .gjs-sm-btn{
  background-color: rgba(40, 40, 40, 0.9);
  color: #fff;
  text-shadow: 0px 0px 0 rgba(0,0,0,.2);
}

.gjs-category-title, .gjs-layer-title, .gjs-block-category .gjs-title, .gjs-sm-sector-title {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 0px;
}

.gjs-category-open, .gjs-block-category.gjs-open, .gjs-sm-sector.gjs-sm-open {
  border-bottom: 0px 
}

.view-page, .page-size{
  font-weight: 200;
  font-size: 14px;
}

.view-page:hover, .page-size:hover{
  text-decoration: none;
}


cursor-default:hover {
  cursor: default;
  text-decoration: none;
}
