.product-header{
    border: 0px;
}


.product-fixed-create-button{
    top: 85%;
    right: 10%;
    left: auto;
    width: 40px;
    height: 40px;
}

.product-edit-action{
    background-color: white;
    border: 1px solid white;
    border-radius: 2px;
}

.product-search-img img{
    width:60px;
    height:60px
}