.customer-header{
    border: 0px;
}


.customer-fixed-create-button{
    top: 85%;
    right: 10%;
    left: auto;
    width: 40px;
    height: 40px;
}

.name,
.emailAddress,
.phoneNumber{
    color: rgb(0, 0, 0);
}
.name:hover,
.emailAddress:hover,
.phoneNumber:hover{
    color: rgb(0, 0, 0);
    text-decoration: none;
}

