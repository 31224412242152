/* Pricing table */

.pricing-table{
  .pricing-card{
    .pricing-card-body{
      /*padding: 50px 56px 43px 56px;*/
      padding: 50px 22px 43px 22px;
      @media (max-width: 767px) {
        padding: 1rem;
      }
      .pricing-card-head{
        height: 150px;
      }
      .plan-features{
        width: 100%;
        margin-bottom: 15px;
        li{
          text-align: left;
          padding: 4px 0px;
          font-weight: 400;
          font-size: $default-font-size;
        }
      }
    }
  }
}