.inquiry-header{
    border: 0px;
}

.inquiry-status{
    background-color: #e6e6e6;
}

.inquiry-fixed-create-button{
    top: 85%;
    right: 10%;
    left: auto;
    width: 40px;
    height: 40px;
}